import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../layouts/index"
import SEO from "../components/seo"
import * as deviceUtils from "../utils/device"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
    }
  }

  componentDidMount() {
    this.initGlobalEvents()
  }

  addActiveClass = e => {
    e.preventDefault()
    const currentSlideItem = document.querySelector(".slides .slide.selected")
    const currentNavLink = document.querySelector(".slider-nav li.active")
    const target = e.target.hash
    currentSlideItem.classList.remove("selected")
    currentNavLink.classList.remove("active")
    document.querySelector(target).classList.add("selected")
    e.target.parentElement.classList.add("active")
  }

  initSlider = () => {
    const slider = document.querySelector(".slides")
    let counter = 0
    let isScrolling
    let currentNavLink = document.querySelector(".slider-nav li.active")
    let currentSlideItem = document.querySelector(".slides .slide.selected")
    const firstNavLink = document.querySelector(".slider-nav li:first-child")
    const firstSlideItem = document.querySelector(".slides .slide:first-child")
    const logoLink = document.querySelector(".brand-item")

    slider.addEventListener("wheel", e => {
      if (slider === null || deviceUtils.getDevice() === "mobile") {
        return
      }

      currentNavLink = document.querySelector(".slider-nav li.active")
      currentSlideItem = document.querySelector(".slides .slide.selected")
      window.clearTimeout(isScrolling)
      isScrolling = setTimeout(() => {
        counter = 0
      }, 100)

      if (e.deltaY < 0 && counter < 1) {
        if (currentNavLink.previousElementSibling != null && currentSlideItem.previousElementSibling != null) {
          currentNavLink.previousElementSibling.classList.add("active")
          currentSlideItem.previousElementSibling.classList.add("selected")
          currentSlideItem.classList.remove("selected")
          currentNavLink.classList.remove("active")
        }
        // increment counter
        counter += 1
      }

      if (e.deltaY > 0 && counter < 1) {
        if (currentNavLink.nextElementSibling != null && currentSlideItem.nextElementSibling != null) {
          currentNavLink.nextElementSibling.classList.add("active")
          currentSlideItem.nextElementSibling.classList.add("selected")
          currentSlideItem.classList.remove("selected")
          currentNavLink.classList.remove("active")
        }
        // increment counter
        counter += 1
      }
    })

    logoLink.addEventListener("click", e => {
      e.preventDefault()
      currentNavLink = document.querySelector(".slider-nav li.active")
      currentSlideItem = document.querySelector(".slides .slide.selected")
      currentNavLink.classList.remove("active")
      currentSlideItem.classList.remove("selected")
      firstNavLink.classList.add("active")
      firstSlideItem.classList.add("selected")
    })
  }

  initGlobalEvents() {
    this.initSlider()
  }

  render() {
    const { data } = this.state
    const posts = data.allWordpressPost.edges.slice(-2)
    const projects = data.allWordpressWpProjects.edges.slice(-2)
    return (
      <Layout cssClass="homepage" hasParticles hasThemeSwitcher>
        <SEO title="Accueil" />
        <div className="main-content slides">
          <section className="slide selected" id="section01">
            <div className="row">
              <h1 className="heading-title">
                <span className="greeting">Bonjour, je m'appelle</span>
                <span className="name">Laurent Panek</span>
              </h1>
              <p className="heading-subtitle">
                Je suis étudiant en Architecture Informatique et Systèmes d'information
              </p>
            </div>
          </section>
          <section className="slide" id="section02">
            <div className="row">
              <div className="column is-2 slide-title">
                <h2>Projets</h2>
              </div>
              <div className="column is-10 slide-content">
                <div className="slide-content-container">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse rutrum purus tellus. Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat vel magna et varius. Duis quis
                    nulla at mauris lobortis bibendum sed nec orci
                  </p>
                </div>
                <div className="slide-content-container">
                  <ul className="row">
                    <li className="column is-10 item">
                      {projects.map(({ node }) => (
                        <div key={node.slug} className="item-container">
                          <div className="item-meta">
                            <h3 className="item-title" dangerouslySetInnerHTML={{ __html: node.title }} />
                            <span className="item-category">Catégorie</span>
                          </div>
                          <div className="item-view">
                            <Link to={`/projets/${node.slug}`}>
                              <span className="arrow-icon" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </li>
                    <li className="column is-2 cta">
                      <Link to="/projets">
                        <span>Voir plus</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="slide" id="section03">
            <div className="row">
              <div className="column is-2 slide-title">
                <h2>Blog</h2>
              </div>
              <div className="column is-10 slide-content">
                <div className="slide-content-container">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse rutrum purus tellus. Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat vel magna et varius. Duis quis
                    nulla at mauris lobortis bibendum sed nec orci
                  </p>
                </div>
                <div className="slide-content-container">
                  <ul className="row">
                    <li className="column is-10 item">
                      {posts.map(({ node }) => (
                        <div key={node.slug} className="item-container">
                          <div className="item-meta">
                            <h3 className="item-title" dangerouslySetInnerHTML={{ __html: node.title }} />
                            <span className="item-category">Catégorie</span>
                          </div>
                          <div className="item-view">
                            <Link to={`/blog/${node.slug}`}>
                              <span className="arrow-icon" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </li>
                    <li className="column is-2 cta">
                      <Link to="/blog">
                        <span>Voir plus</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="slide" id="section04">
            <div className="row">
              <div className="column is-2 slide-title">
                <h2>Autre</h2>
              </div>
              <div className="column is-10 slide-content">
                <div className="slide-content-container">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse rutrum purus tellus. Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat vel magna et varius. Duis quis
                    nulla at mauris lobortis bibendum sed nec orci
                  </p>
                </div>
                <div className="slide-content-container">
                  <ul className="row">
                    <li className="column is-10 item">
                      <div className="item-container">
                        <div className="item-meta">
                          <h3 className="item-title">More content</h3>
                          <span className="item-category">Catégorie</span>
                        </div>
                        <div className="item-view">
                          <a href="/">
                            <span className="arrow-icon" />
                          </a>
                        </div>
                      </div>
                      <div className="item-container">
                        <div className="item-meta">
                          <h3 className="item-title">More content</h3>
                          <span className="item-category">Catégorie</span>
                        </div>
                        <div className="item-view">
                          <a href="/">
                            <span className="arrow-icon" />
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="column is-2 cta">
                      <a href="/blog">
                        <span>Voir plus</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="slider-nav">
          <ul>
            <li className="active">
              <a onClick={this.addActiveClass} href="#section01">
                01
              </a>
            </li>
            <li>
              <a onClick={this.addActiveClass} href="#section02">
                02
              </a>
            </li>
            <li>
              <a onClick={this.addActiveClass} href="#section03">
                03
              </a>
            </li>
            <li>
              <a onClick={this.addActiveClass} href="#section04">
                04
              </a>
            </li>
          </ul>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          post_categories {
            text_color
            color
            title
          }
        }
      }
    }
    allWordpressWpProjects(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}
